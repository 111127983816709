<template>
  <v-card>
    <v-tabs v-model="tab" class="deep-gl" left dark icons-and-text>
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-1">
        <v-toolbar-title>
          <slot name="title"></slot>
        </v-toolbar-title>
      </v-tab>
      <v-spacer></v-spacer>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card flat>
          <v-card-text>
            <Table
              :path="path"
              :tableId="tableId"
              dbTable="company_contacts"
              :tableInit="tableInit"
              :showParams="showParams"
              :filtersFields="filtersFields"
              :clickedDetails="clickedDetails"
            ></Table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>

import { TABLE_INIT_URL, GET_COMPANY_CONTACTS_INIT } from "@/store/companyContact.module";
import Table from "@/views/partials/table/Table.vue";

export default {
  name: "ShowContacts",
  components: {
    Table,
  },
  props: ["tableId"],
  mounted() {},
  data() {
    return {
      isProcessing: false,
      path: TABLE_INIT_URL,
      tableInit: GET_COMPANY_CONTACTS_INIT,
      tab: "tab-1",
      showParams: { name: "view-contact", key: "id" },
      filtersFields: [],
      clickedDetails:{
          vendor_offers_count:{'url':'list-offers','column':'email','targetTable':'vendorOffersTable','targetColumn':'company_contacts.email'},
          client_requests_count:{'url':'list-requests','column':'email','targetTable':'requestsTable','targetColumn':'company_contacts.email'}
      }
    };
  },
  methods: {},
};
</script>

<style scoped>
.tag:not(body).is-danger {
  background-color: none;
}
</style>

