<template>
  <div>
    <!--Begin::LIST CONTACTS-->
    <KTPortlet>
      <template v-slot:toolbar></template>
      <template v-slot:body>
        <ShowContacts :key="offersKey" @reset="offersKey += 1" table-id="companyContacts">
          <template slot="title">{{
            $t("COMPANY_CONTACTS.SUB_TITLE")
          }}</template>
        </ShowContacts>
      </template>
    </KTPortlet>
    <!--End::LIST CONTACTS-->
  </div>
</template>

<script>
import ShowContacts from "./ShowContacts.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: { KTPortlet, ShowContacts },
  name: "CompanyContacts",
  data() {
    return {
      offersKey: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("COMPANY_CONTACTS.TITLE") },
    ]);
  },
};
</script>

<style>
</style>